<template>
  <section>
    <div class="grid grid-cols-1 gap-5 mx-5">
      <div class="flex flex-col ">
        <span class="font-bold">Nombre Régimen</span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.nombre" />
        <MessageError :text="errors.nombre"/>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import RegimenService from '../../../../../services/regimen.service'

// instancias
const _RegimenService = ref(new RegimenService())
const emit = defineEmits(['customChange'])
const props = defineProps({
  idReg: String
})
// referencias

const validationSchema = yup.object({
  nombre: yup.string().required('El nombre es requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('nombre', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string') {
        model[index] = item.toUpperCase()
      }
    }
    if (props.idReg) {
      _RegimenService.value.editarReg(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Régimen Actualizado',
          text: 'El Régimen se ha actualizado correctamente'
        }).then(() => {
          emit('crearReg')
        })
      })
    } else {
      _RegimenService.value.crearReg(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Régimen creado',
          text: 'El Régimen se ha creado correctamente'
        }).then(() => {
          emit('crearReg')
        })
      })
    }
})
const resetFilters = () => {
  model.value = {
    Code: '',
    Name: ''
  }
}

onMounted(() => {
  if (props.idReg) {
    _RegimenService.value.getReg(props.idReg).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
    })
  }
})
</script>
<style>
</style>
