<template>
    <section>
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="w-full flex gap-2 items-center">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listado de Regimen</p>
          <Button @click="displayModalReg = true, idReg = null" class="ml-auto rounded-md" label="Crear Régimen" />
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <!-- filtro por cod -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cod">Id</label>
            <InputText class="w-full" id="cod" @keyup.enter="listarReg" v-model="filtros.id" />
          </div>
          <!-- filtro por nombre -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="desc">Nombre Regimen</label>
            <InputText class="w-full" id="desc" @keyup.enter="listarReg" v-model="filtros.nombre"/>
          </div>
          <div class="flex gap-2">
            <Button @click="listarReg" class="mt-5 mb-1.5 rounded-md" label="Buscar" />
            <Button @click="limpiarFiltros" class="mt-5 mb-1.5 rounded-md p-button-outlined p-button-secondary" label="Limpiar" />
          </div>
        </div>
        <div class="w-full my-2">
          <DataTable class="p-datatable-sm text-xs" :value="regimenes.rows" responsiveLayout="scroll">
            <Column header="Cod diag">
              <template #body="{data}">
                <div class=" pt-3">
                  <p>{{ data.id }}</p>
                  <div class="flex gap-x-4 hover:text-blue-600 text-white transition ease-in-out duration-500">
                    <button class="underline italic" @click='EditReg(data.id)'>Editar</button>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="nombre" header="Descripción Frecuencia"></Column>

            <Column header="Acción">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" >
                  <Button
                      @click='DeleteReg(data.id)'
                      severity="danger"
                      v-tooltip="'Eliminar'"
                      class='p-button-rounded p-button-icon-only mx-2'
                  >
                    <i class="pi pi-trash text-white"></i>
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(regimenes.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
      <Dialog :header="`${idReg ? 'Actualizar' : 'Crear'} Régimen`" v-model:visible="displayModalReg" :breakpoints="{'960px': '95vw'}" :style="{width: '40vw'}" :modal="true">
        <div class="grid gap-4 bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xxl:grid-cols-5">
          <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
            <crearReg @crearReg="displayModalReg = false, listarReg()" :idReg="idReg" />
          </div>
        </div>
      </Dialog>
      <Toast />
    </section>
</template>
<script setup>
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  import RegimenService from '../../../../../services/regimen.service'
  import { onMounted, ref, computed } from 'vue'
  import crearReg from './crear.vue'
    // name: 'ListadoRegimenes'
    // Servicios
    const toast = useToast()
    const _RegimenService = ref(new RegimenService())
    // Referencias
    const regimenes = ref([])
    const limit = ref(10)
    const offset = ref(0)
    const filtros = ref({
      Code: null,
      Name: null
    })
    const idReg = ref(null)
    const displayModalReg = ref(false)
    const params = computed(() => {
      return {
        limit: limit.value,
        offset: offset.value,
        ...filtros.value
      }
    })
    // Metodos
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      listarReg(params.value)
    }
    const listarReg = () => {
      const object = {
        ...params.value,
        limit: limit.value,
        offset: offset.value
      }
      _RegimenService.value.paginate(object).then(({ data }) => {
        regimenes.value = data
      })
    }
    const EditReg = (id) => {
      displayModalReg.value = true
      idReg.value = id
    }
    const DeleteReg = (id) => {
      Swal.fire({
          title: 'Esta seguro de eliminar este Régimen?',
          showDenyButton: true,
          denyButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar'
        }).then((result) => {
          if (result.isConfirmed) {
            _RegimenService.value.eliminarReg(id).then(({ data }) => {
              if (data.error) {
                console.error('Error al eliminar el Régimen, por favor intente nuevamente')
              } else {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Régimen eliminado exitosamente', life: 3000 })
              }
              listarReg()
            })
          } else if (result.isDenied) {
            Swal.fire('Régimen no eliminado', '', 'info')
          }
        })
    }
    const limpiarFiltros = () => {
      filtros.value = {
        nomMpio: null,
        nomDpto: null
      }
      listarReg()
    }
    onMounted(() => {
      listarReg()
    })
</script>
<style>
  .p-dropdown-label{
    font-size: 0.75rem !important
  }
  .tipoId .p-dropdown-trigger {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
</style>
